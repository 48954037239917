<template>
  <div class="shop-photos">
    <div class="col-md-12 text-right mb-2">
      <b-button
        variant="primary"
        @click="showModal"
      >
        {{ $t('Add Photo') }}
      </b-button>
    </div>
    <b-row>
      <b-col
        v-for="(item, index) of allPhotos"
        :key="index"
        md="3"
      >
        <div class="card">
          <div class="product-box">
            <div class="product-img">
              <img
                class="img-fluid w-100"
                style="height: 250px;"
                :src="$helpers.imageHelper(item.photo)"
                @error="$helpers.imageError"
              >
              <div class="product-hover">
                <ul>
                  <li>
                    <button
                      class="btn"
                      @click="editStorePhoto(item.id)"
                    >
                      <img :src="require('@/assets/images/app-icons/edit_grid.png')">
                    </button>
                  </li>
                  <li>
                    <button
                      type="button"
                      class="btn"
                      @click="deleteImage(item)"
                    >
                      <img :src="require('@/assets/images/app-icons/delete_grid.png')">
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="product-details">
              <p style="margin-bottom: 5px;">
                {{ $t('Main Image') }}: <span class="badge badge-pill badge-primary text-white"> {{ item.is_main ? 'Yes' : 'No' }} </span>
              </p>
              <p class="mb-0">
                {{ $t('Menu Image') }}: <span class="badge badge-pill badge-success text-white"> {{ item.is_menu ? 'Yes' : 'No' }} </span>
              </p>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      v-model="showPhotosViewModal"
      :no-close-on-backdrop="true"
      :title="activeStorePhoto && activeStorePhoto.id ? 'Edit Photos' : 'Add Photos'"
      ok-only
      hide-footer
      :ok-title="$t('Save Changes')"
      @hidden="showPhotosViewModal = false"
      @ok="savePhotos"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group :label="$t('Picture')">
            <image-input
              :default-image="activeStorePhoto.photo"
              :class="{ 'is-invalid': !activeStorePhoto.photo && isDataSubmitted }"
              @input-file="inputFile"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="activeStorePhoto.is_main"
              name="is_main"
              switch
              inline
            >
              <span> {{ $t('Main Image') }}</span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group>
            <b-form-checkbox
              v-model="activeStorePhoto.is_menu"
              name="is_menu"
              switch
              inline
            >
              <span> {{ $t('Menu Image') }}</span>
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
      <modal-footer
        :show-cancel="false"
        @ok="savePhotos"
      />
    </b-modal>
  </div>
</template>

<script>
import { BRow, BButton, BCol, BModal, BFormGroup, BFormCheckbox } from 'bootstrap-vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import ModalFooter from '@/@core/components/modal-footer/ModalFooter.vue'
import { useStoreUi } from './useStore'
import ImageInput from '../users/shared/ImageInput.vue'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    ModalFooter,
    BModal,
    BFormGroup,
    ImageInput,
    BFormCheckbox,
  },
  data() {
    return {
      isDataSubmitted: false,
      showPhotosViewModal: false,
      allPhotos: [],
      userPhotos: null,
      activeStorePhoto: {
        store_id: '',
        overview: '',
      },
    }
  },
  computed: {
    storeId() {
      return window.SuperApp.getters.userInfo().id
    },
    isStore() {
      return window.SuperApp.getters.isStore()
    },
  },
  mounted() {
    this.getAllPhotos()
  },
  methods: {
    deleteImage(props) {
      this.$swal({
        title: this.$t('You want to delete the photo?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteStorePhoto(props.id)
        }
      })
    },
    getAllPhotos() {
      const { executeGetApi } = useStoreUi()
      showLoader()
      executeGetApi(this.getApiEndpoint())
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.allPhotos = data.responseData.data
            this.userPhotos = data.responseData
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    inputFile(payload) {
      if (this.activeStorePhoto) this.activeStorePhoto.photo = payload
    },
    photoPayload() {
      return {
        photo: '',
        is_menu: false,
        is_main: false,
        id: 0,
      }
    },
    getApiEndpoint() {
      return `/${this.isStore ? 'shop' : 'provider'}/photos/${this.storeId}`
    },
    showModal() {
      this.showPhotosViewModal = true
      this.activeStorePhoto = this.photoPayload()
      this.isDataSubmitted = false
    },
    deleteStorePhoto(payload) {
      const { deleteStorePhoto } = useStoreUi()

      showLoader()
      deleteStorePhoto(payload)
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.getAllPhotos()
          } else {
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    editStorePhoto(payload) {
      this.activeStorePhoto = this.photoPayload()
      const { getPhotoDetail } = useStoreUi()
      showLoader()
      getPhotoDetail(payload)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.activeStorePhoto = data.responseData
            this.activeStorePhoto.is_main = Boolean(this.activeStorePhoto.is_main)
            this.activeStorePhoto.is_menu = Boolean(this.activeStorePhoto.is_menu)
            this.activeStorePhoto.photo = window.SuperApp.helper.image(this.activeStorePhoto.photo)
            this.showPhotosViewModal = true
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    savePhotos() {
      this.isDataSubmitted = true
      setTimeout(() => {
        if (window.SuperApp.validations.anyErrorInModal()) {
          return
        }
        this.showPhotosViewModal = false
        this.isDataSubmitted = false
        if (!this.activeStorePhoto.id) {
          this.createPhotos()
        } else {
          this.updatePhotos()
        }
      })
    },
    createPhotos() {
      const { addStorePhoto } = useStoreUi()

      showLoader()

      const formData = new FormData()
      formData.append(this.isStore ? 'store_id' : 'provider_id', window.SuperApp.getters.userInfo().id)
      formData.append('picture', this.activeStorePhoto.photo)
      formData.append('is_main', Number(this.activeStorePhoto.is_main))
      formData.append('is_menu', Number(this.activeStorePhoto.is_menu))

      addStorePhoto(formData)
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.getAllPhotos()
            this.showPhotosViewModal = false
          } else {
            this.showPhotosViewModal = true
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          this.showPhotosViewModal = true
          showErrorNotification(this, error)
        })
    },
    updatePhotos() {
      const { editStorePhoto } = useStoreUi()
      showLoader()
      const formData = new FormData()
      formData.append(this.isStore ? 'store_id' : 'provider_id', window.SuperApp.getters.userInfo().id)
      if (window.SuperApp.helper.withBaseUrl(this.activeStorePhoto.photo)) formData.append('picture', this.activeStorePhoto.photo)
      formData.append('is_main', Number(this.activeStorePhoto.is_main))
      formData.append('is_menu', Number(this.activeStorePhoto.is_menu))
      formData.append('_method', 'PATCH')

      editStorePhoto({
        data: formData,
        query: this.activeStorePhoto.id,
      })
        .then(({ data }) => {
          hideLoader()
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
            this.showPhotosViewModal = false
            this.getAllPhotos()
          } else {
            this.showPhotosViewModal = true
            showDangerNotification(this, data.message)
          }
        })
        .catch(error => {
          this.showPhotosViewModal = true
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.card-image-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid 1px solid #ecf3fa;
  border-radius: 0.25rem;
  margin-bottom: 30px;
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 15px;
  box-shadow: 0 0 20px rgb(8 21 66 / 5%);
}

.product-box {
  border-radius: 10px;
  overflow: hidden;
}

.product-box .product-img {
  position: relative;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.product-box .product-img .product-hover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  border-radius: 100%;
  transform: scale(0);
  transition: all 0.3s ease;

  ul {
    padding-left: 0px;
    list-style-type: none;
    margin-bottom: 0;

    li {
      display: inline-block;
      box-shadow: 1px 1px 2px 1px rgb(0 0 0 / 8%);
      padding: 10px 12px;
      background-color: #fff;
      font-size: 18px;
      border-radius: 10px;
      height: 45px;
      width: 45px;
      margin: 0 3px;
      cursor: pointer;
      text-align: center;

      .btn {
        padding: 0;
      }
    }
  }
}

.product-box .product-details {
  padding: 15px 25px;
}

.product-box:hover .product-hover {
  opacity: 1;
  border-radius: 0%;
  transform: scale(1);
  transition: all 0.3s ease;
}

.product-box .product-hover {
  img {
    width: 20px;
  }
}
</style>
