<template>
  <footer class="modal-footer row">
    <button
      v-if="showCancel"
      class="btn btn-secondary"
      @click="$emit('hidden')"
    >
      {{ $t('Cancel') }}
    </button>
    <button
      v-if="showOk"
      class="btn btn-primary"
      @click="$emit('ok')"
    >
      {{ $t(okText) }}
    </button>
  </footer>
</template>

<script>
export default {
    props: {
        showCancel: {
            type: Boolean,
            default: true,
        },
        showOk: {
            type: Boolean,
            default: true,
        },
        okText: {
          type: String,
          default: 'Save Changes',
        },
    },
}
</script>
